<template>
    <div class="app flex-row align-items-center">

        <b-row style="max-width: 100%;padding-left:0px !important;padding-right: 0px; !important;margin: 0px;">




            <div id="principal" class="col-md-12">
                <mdb-row class="mt-5 align-items-center justify-content-start">
                    <h4 class="demo-title"><strong>Perfil de Usuario</strong></h4>
                </mdb-row>

                <hr />

                <mdb-row>

                    <div class="col-md-2">
                        <mdb-card>
                            <mdb-card-body>
                                <mdb-card-title v-if="usuario">{{usuario.username}}</mdb-card-title>
                                <mdb-card-text><p v-if="usuario">{{usuario.email}}</p>
                                    <p v-if="usuario">{{usuario.role}}</p>
                                <p v-if="usuario">Registro : {{FechaRegistro(usuario.created_at)}}</p></mdb-card-text>
                                <br />
                                <a href="#" @click="logout()">Cerrar Sesión</a>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                    <div class="col-md-10">
                        <mdb-tabs
                                :active="0"
                                default
                                :links="[
          { text: 'Configuración' },
          { text: 'Actividad' }]"
                                :transition-duration="0.5"
                                transition-style="linear"
                        >
                            <template :slot="'Actividad'" style="width: 100%">
                                <mdb-container>

                                    <b-row>
                                        <b-table striped hover  @row-clicked="IrDetalle"  style="width: 100%; cursor: pointer" :fields="campos_actividad"  :items="actividad">



                                        </b-table>
                                    </b-row>

                                    <mdb-row>
                                        <div style="width: 100%">
                                            <mdb-row style="width: 100%">
                                                <div class="col-md-3">
                                                    <mdb-btn color="primary" style="margin-left: 35px;" v-if="pagina_actual > 1" @click="IrPaginaAnterior" size="lg">Atrás</mdb-btn>
                                                </div>
                                                <div class="col-md-6">
                                                </div>
                                                <div class="col-md-3">
                                                    <mdb-btn color="primary" style="float: right;" @click="IrPaginaSiguiente" size="lg">Siguiente</mdb-btn>
                                                </div>

                                            </mdb-row>
                                        </div>
                                    </mdb-row>
                                    <br />
                                    <br />
                                </mdb-container>
                            </template>
                            <template :slot="'Configuración'" style="width: 100%;">
                                <section>
                                    <b-row style="padding: 20px">
                                        <div class="col-md-6" >
                                            <label for="Nombre de Usuario" style="padding-top:15px">Nombre de Usuario</label>
                                            <input type="text" id="username"  v-model="usuario.username" class="form-control">
                                        </div>
                                        <div class="col-md-6">
                                            <mdb-btn color="primary" @click="ActualizarNombre()" size="lg" style="margin-top: 35px;">Cambiar Nombre de Usuario</mdb-btn>
                                        </div>


                                    </b-row>
                                    <b-row style="padding: 20px">
                                        <div class="col-md-6" >
                                            <label for="Nombre de Usuario" style="padding-top:15px">Email</label>
                                            <input type="text" disabled id="email"  v-model="usuario.email" class="form-control">
                                        </div>



                                    </b-row>
                                    <b-row style="padding: 20px">
                                        <div class="col-md-3" style="padding-top:10px">
                                            <form>
                                                <div class="form-group">
                                                    <div class="custom-control custom-switch" style="padding:40px;margin-left: 20px">
                                                        <input type="checkbox" class="custom-control-input"  @change="ActualizarAvisos()" v-model="usuario.acepto_avisos_contenido" id="customSwitches">
                                                        <label class="custom-control-label"  for="customSwitches" >Aceptar avisos de contenido <a href="#" @click="MostrarInfoAvisos()">(+Info)</a> </label>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </b-row>
                                    <b-row style="padding: 20px">
                                        <div class="col-md-3" >
                                            <mdb-btn color="primary" size="lg" style="margin-top: 35px;">Descargar mis datos</mdb-btn>
                                        </div>
                                        <div class="col-md-3">
                                            <mdb-btn color="danger" size="lg" @click="EliminarCuenta()" style="margin-top: 35px;">Eliminar Cuenta</mdb-btn>
                                        </div>


                                    </b-row>
                                </section>
                            </template>


                        </mdb-tabs>
                    </div>
                </mdb-row>

            </div>


        </b-row>

        <vue-cookie-accept-decline
                :ref="'myPanel1'"
                :elementId="'myPanel1'"
                :debug="false"
                :position="'bottom-left'"
                :type="'floating'"
                :disableDecline="true"
                :transitionName="'slideFromBottom'"
                :showPostponeButton="false"
                @status="cookieStatus"
                @clicked-accept="cookieClickedAccept"
                @clicked-decline="cookieClickedDecline">

            <!-- Optional -->
            <div slot="postponeContent">
                &times;
            </div>

            <!-- Optional -->
            <div slot="message">
                Agustinos.tv cumple con la normativa de cookies y privacidad.<a href="https://agustinostv-front.archive.tornadocore.es/proteccion_de_datos.pdf" target="_blank">Leer más </a>
            </div>


            <!-- Optional -->
            <div slot="acceptContent">
                ACEPTAR
            </div>
        </vue-cookie-accept-decline>
    </div>



</template>

<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbCol,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbTabs,
        mdbJumbotron,
        mdbModalFooter,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';

    export default {
        name: 'Profile',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbCol,
            mdbBtn,
            mdbModal,
            mdbTabs,
            mdbJumbotron,
            mdbModalHeader,
            mdbModalTitle,
            mdbModalBody,
            mdbModalFooter,
            mdbCardGroup,
        },
        data() {
            return {
                VerFiltroMovil: false,
                token : localStorage.getItem('token'),
                esta_en_vertical: false,
                acepto_avisos_contenido : 0,
                pagina_actual : 1,
                is_mobile:'',
                id : localStorage.getItem('user_id'),
                usuario : null,
                actividad : [],
                campos_actividad: [
                    {key: 'motivo', label: 'Acción', sortable: true },
                    {key: 'detalle', label: 'Detalle',sortable: true},
                    {key: 'created_at', label: 'Fecha', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')} },
                ],

            }
        },
        methods: {
            EliminarCuenta()
            {
                var that = this;
                this.$swal.fire({
                    title: '¿Estás seguro que quieres borrar tu cuenta?',
                    text: 'Esta acción es irreversible y tu correo quedará inutilizable en el sistema salvo que contactes con la administración',
                    type: 'warning',
                    showCancelButton: true,
                }).then(function(confirm) {

                    if (!confirm.dismiss) {
                        ApiService.UserDeleteAccount(that.usuario.id).then(r => {
                            if (r.status == 200) {
                                this.$router.push({path: '/auth/login'});
                            } else {
                                this.$swal.fire({
                                    position: 'center',
                                    type: 'error',
                                    title: 'Ha habido un error',
                                    message: r.data.message,
                                    showConfirmButton: false,
                                    timer: 3000
                                }).then(r => {
                                    this.$router.go();
                                });

                            }
                        });

                    }

                });


            },
            FechaRegistro(value)
            {
                return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
            },
            IrDetalle(item,index,event)
            {
                console.log(item);
                if(item.motivo === "Like a Vídeo")
                {
                    this.$router.push({ path: '/video/' + item.id});
                }


            },
            logout(){
                ApiService.logout().then( response => {
                    localStorage.removeItem('token');
                    this.$swal.fire({
                        position: 'center',
                        type: 'success',
                        title: 'Desconectado con éxito',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(r => {

                        this.$router.go();

                    });



                })

            },
            MostrarInfoAvisos() {
                this.$swal.fire({
                    position: 'center',
                    type: 'info',
                    title: 'Activar o desactivar avisos de contenido',
                    text: 'Estos avisos son los que el sistema o la administración envía para comunicar inicio o estado de eventos programados, directos y  subida de contenido. El cambio de este parámetro puede tardar en aplicarse máximo 24h',
                    showConfirmButton: true,
                }).then(r => {
                })

            },
            IrPaginaSiguiente()
            {
                this.pagina_actual = this.pagina_actual + 1;
                this.ReloadData();
                var element = document.getElementById("app");
                var top = element.offsetTop;
                window.scrollTo(0, top);
            },
            IrPaginaAnterior()
            {
                this.pagina_actual = this.pagina_actual - 1;
                this.ReloadData();
                var element = document.getElementById("app");
                var top = element.offsetTop;
                window.scrollTo(0, top);
            },
            FechaSQLtoFechaES(entrada)
            {
                return  moment(String(entrada)).format('DD/MM/YYYY hh:mm:ss');
            },
            //Ordenar las novedades de más reciete a anterior (Son distintos tipos de objetos)
            compare(a, b) {

                var dateA = moment(a.created_at, "YYYY-MM-DD"); // replace format by your one
                var dateB = moment(b.created_at, "YYYY-MM-DD");

                if(dateA.diff(dateB) > 0) {
                    return -1;
                }
                if(dateB.diff(dateA) > 0) {
                    return 1;
                }
                return 0;

            },
            ReloadData()
            {
                ApiService.getUser(this.id).then(response => {
                    this.usuario = response.data;
                    console.log('usuario');
                    console.log(this.usuario);

                    this.acepto_avisos_contenido = response.data.acepto_avisos_contenido;

                    ApiService.getUserAcitivdad(this.usuario.id,this.pagina_actual).then( r => {
                       if(r.data)
                       {
                           this.actividad = [];




                           if(r.data.videos_likes)
                           {
                               r.data.videos_likes.forEach( e => {
                                   this.actividad.push({motivo : "Like a Vídeo",id: e.video_id,nombre : e.title,created_at: e.created_at});
                               });
                           }
                           if(r.data.eventos_likes)
                           {
                               r.data.eventos_likes.forEach( e => {
                                   this.actividad.push({motivo : "Like a Evento en directo",id: e.evento_id,nombre : e.title,created_at: e.created_at});
                               });
                           }
                           if(r.data.sesiones)
                           {
                               r.data.sesiones.forEach( e => {
                                   this.actividad.push({motivo : "Inicio de Sesión",isMobile: e.isMobile,ip: e.ip, webData: e.webBrowserData,created_at: e.created_at});
                               });
                           }

                           this.actividad.sort(function (a,b) {
                               return b.created_at > a.created_at;
                           });

                           this.actividad.forEach(a => {
                               if(a.motivo == 'Like a Vídeo')
                               {
                                   a.detalle =  a.nombre;
                               }
                               if(a.motivo == 'Like a Evento en directo')
                               {
                                   a.detalle =  a.nombre;
                               }
                               if(a.motivo == 'Inicio de Sesión')
                               {
                                   a.detalle = 'Ip: ' + a.ip + ", EsMovil: " + a.isMobile + ", DatosNavegador: " + a.webData;
                               }
                           })

                           console.log(this.actividad);

                       }
                       else {
                           this.actividad = [];
                       }


                    });

                });

            },
            ActualizarNombre()
            {
                console.log('aaa');
                ApiService.UserCambiarNombre(this.usuario.id,this.usuario.username).then( r => {

                    if(r.status == 200 && r.data.status == 'success')
                    {
                        this.$swal.fire({
                            position: 'center',
                            type: 'success',
                            title: 'Nombre actualizado con éxito',
                            showConfirmButton: false,
                            timer: 3000
                        }).then( r => {
                            this.$router.go();
                        })


                    }
                    else
                    {
                        this.$swal.fire({
                            position: 'center',
                            type: 'error',
                            title: 'Ha habido un error',
                            message: r.data.message,
                            showConfirmButton: false,
                            timer: 3000
                        }).then( r => {
                            this.$router.go();
                        })

                    }
                }).catch( e => {
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Ha habido un error',
                        showConfirmButton: false,
                        timer: 3000
                    }).then( r => {
                        this.$router.go();
                    });
                });
            },
            ActualizarAvisos()
            {

                if(this.acepto_avisos_contenido == 0) this.acepto_avisos_contenido = 1;
                else if(this.acepto_avisos_contenido == 1) this.acepto_avisos_contenido = 0;
                console.log(this.acepto_avisos_contenido);
                console.log(this.usuario.id);

                ApiService.UserCambiarAvisos(this.usuario.id,this.acepto_avisos_contenido).then( r => {

                    if(r.status == 200 && r.data.status == 'success')
                    {
                        this.$swal.fire({
                            position: 'center',
                            type: 'success',
                            title: 'Avisos actualizados correctamente',
                            showConfirmButton: false,
                            timer: 3000
                        }).then( r => {
                            //this.$router.go();
                        })


                    }
                    else
                    {
                        this.$swal.fire({
                            position: 'center',
                            type: 'error',
                            title: 'Ha habido un error',
                            message: r.data.message,
                            showConfirmButton: false,
                            timer: 3000
                        }).then( r => {
                           // this.$router.go();
                        })

                    }
                }).catch( e => {
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Ha habido un error',
                        showConfirmButton: false,
                        timer: 3000
                    }).then( r => {
                        //this.$router.go();
                    });
                });
            },

        /*ActualizarEmail()
        {
            console.log('aaa');
            ApiService.UserCambiarEmail(this.usuario.id,this.usuario.email).then( r => {

                if(r.status == 200 && r.data.status == 'success')
                {
                    this.$swal.fire({
                        position: 'center',
                        type: 'success',
                        title: 'Email actualizado con éxito',
                        showConfirmButton: false,
                        timer: 3000
                    }).then( r => {
                        this.$router.go();
                    });


                }
                else
                {
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Ha habido un error',
                        message: r.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    }).then( r => {
                        this.$router.go();
                    });

                }
            }).catch(e => {
                this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: 'El email ya está en uso o ya pertenece a alguna lista de correo interna',
                    showConfirmButton: false,
                    timer: 3000
                }).then( r => {
                    this.$router.go();
                });

            });
        },*/

    },
        created(){

            if(window.innerHeight > window.innerWidth)
            {
                this.esta_en_vertical = true;
                document.querySelector("html").style.fontSize = '.80rem';
                this.estilo_row = 'width: 100%;padding-right:5px;padding-left:5px;padding-top:10px;padding-bottom:10px;';
            }

            ApiService.isMobile().then(response => {
                console.log('isMobile');
                console.log(response);
                this.is_mobile = response.data.is_mobile;
                if(localStorage.getItem('is_mobile'))
                {

                    this.is_mobile = localStorage.getItem('is_mobile');
                    console.log('leyendo is_mobile : ' + this.is_mobile );
                }

                else {
                    console.log('asignando is_mobile = ' + this.is_mobile);
                    localStorage.setItem('is_mobile', this.is_mobile);
                    if(this.is_mobile == true) {
                        localStorage.setItem('res_video', '360p');
                    }
                    else {
                        localStorage.setItem('res_video', '720p');
                    }
                    this.$forceUpdate();
                }
            });

            this.ReloadData();
        },
        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }
</style>